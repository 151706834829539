
.course-card-container{
    background-color: #fff;
    margin: 2%;
    height: 16vh;
    width: 14vw;
    border-radius: 10px;
}
.course-inner-container{
    display: grid;
    justify-content: center;
}
.img-title-div{
    display: flex;
    margin-top: 4%;
}
.img-title-div span{
    margin: 8% 0  0 49%;
}
.img-title-div img{
    height: 35px;
    width: 35px;
    border-radius: 100px;
}

