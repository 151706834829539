.profile-main-container{
    width: 54vw;
}

.profile-top{
    background-color: rgb(169, 200, 223);
    border-radius: 1%;
    margin-left: 13px;
}

.profile-top img{
    /* margin-left: 60%; */
}

.profile-header h3{
    margin-left: 20%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 30px;
}

.profile-header p{
    margin-left: 10%;
}

.button2{
    background-color: #89b4c3; 
    border-radius: 20px;
    color: rgb(31, 29, 29);
    padding: 10px 20px;
    margin-left: 30px;
    cursor: pointer;
    font-family:'Times New Roman', Times, serif ;
    margin-bottom: 20px;
    border: none;
}
.button2:hover{
    background-color: rgb(195, 199, 202);
}

.course{
    text-align: center;
    font-family:'Times New Roman', Times, serif ;
    font-size: 25px;
    font-weight: 600;

    

}