.Parent-SignUp-Main-Frame{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
}
.Parent-SignUp-Inner-Frame{
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    height: 98%;
    width: 30%;
    border-radius: 20px;
    border-color: #2257bf;
    background-color: #2257bf;
}
.Parent-Action-Preview-On-SignUp-Page{
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Parent-Action-Preview-On-SignUp-Page img{
    height: 70%;
    width: 35%;
}
.Parent-SignUp-Form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 85%;
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;
    border-color: #2257bf;
    background-color: white;
}
.Parent-SignUp-Form p{
    display: flex;
    flex-direction: column;
}
.Parent-SignUp-Form p label{
    color: #2257bf;
    font-weight: 500;
    font-size: 18px;
}
.Reusable-Sign-Up-Component-Frame{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Parent-SignUp-Form input{
    width: 25vw;
    height: 5vh;
    font-size: 18px;
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-color: #2257bf;
    border-bottom: 3px solid;
}
.Parent-SignUp-Form input:hover{
    background-color: transparent;
}
.SignUp-Button-Div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20%;
}
.Parent-SignUp-Form button{
    width: 10vw;
    height: 40%;
    border-width: 2px;
    border-color: #2257bf;
    color: #2257bf;
}
.Parent-SignUp-Form button:hover{
    background-color: #2257bf;
    color: white;
}