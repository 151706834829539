.Tutor-SignUp-Main-Frame{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.Tutor-SignUp-Inner-Frame{
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: brown;
    border-style: solid;
    border-width: 1px;
    height: 98%;
    width: 30%;
    border-radius: 20px;
    border-color: #2257bf;
    background-color: #2257bf;
}
.Logo-View-On-SignUp-Page{
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.Logo-View-On-SignUp-Page img{
    height: 70%;
    width: 35%;
}
.Tutor-SignUp-Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 85%;
    width: 100%;
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;
    border-color: #2257bf;
    background-color: white;
}
.Tutor-SignUp-Form p{
    display: flex;
    flex-direction: column;
}
.Progress-Bar-Main-Frame{
    display: flex;
    justify-content: space-around;
    width: 90%;
    height: 7%;
}
.Main-Bar-Frame{
    width: 78%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Bar1, .Bar2{
    width: 50%;
    height: 30%;
    border-style: solid;
    border-radius: 20px;
    border-width: 2px;
    border-color: #2257bf;
}
.Circle1, .Circle2{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #2257bf;
    width: 10%;
}
.Tutor-SignUp-Component{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85%;
    width: 90%;
}
.Tutor-SignUp-Form p label{
    color: #2257bf;
    font-weight: 500;
    font-size: 15px;
    height: 80%;
}
.Tutor-SignUp-Form input{
    width: 26.5vw;
    height: 4vh;
    font-size: 18px;
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-color: #2257bf;
    border-bottom: 3px solid #2257bf;
}
.Tutor-SignUp-Button-Div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 20%;
    background-color: #282c34;
    width: 100%;
}
.Tutor-SignUp-Button-Div button{
    width: 10vw;
    height: 8vh;
    border-width: 2px;
    border-color: #2257bf;
    color: #2257bf;
}
.Tutor-SignUp-Button-Div button:hover{
    background-color: #2257bf;
    color: white;
}
.Page-2{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 3%;
    align-items: center;
}
.Select-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.Select-Wrapper select{
    width: 100%;
    height: 50%;
    font-size: 16px;
}
  
.File-Upload-Wrapper, .Select-Wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 40%;
    width: 100%;
}
.File-Upload-Wrapper label, .Select-Wrapper label{
    color: #2257bf;
    font-weight: 500;
    text-shadow: #282c34;
    font-size: 20px;
}
.File-Upload-Wrapper span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30%;
    width: 100%;
    font-size: 15px;
}
.Tutor-SignUp-Component input[type="file"] {
    height: 0;
    padding: 0;
    opacity: 0;
}
.Tutor-SignUp-Component label[for="Qualification-File"]{
    box-shadow: 1px 1px 3px #ccc;
    background: linear-gradient(to bottom, #eee, #ccc);
    border: 1px solid rgb(169, 169, 169);
    border-radius: 5px;
    text-align: center;
    line-height: 1.5;
}

.Tutor-SignUp-Component label[for="Resume-Info"]{
    box-shadow: 1px 1px 3px #ccc;
    background: linear-gradient(to bottom, #eee, #ccc);
    border: 1px solid rgb(169, 169, 169);
    border-radius: 5px;
    text-align: center;
    line-height: 1.5;
}
  
.Tutor-SignUp-Component label[for="Qualification-File"]:hover{
    background: linear-gradient(to bottom, #e1dddd, #dddddda7);
}
.Tutor-SignUp-Component label[for="Resume-Info"]:hover {
    background: linear-gradient(to bottom, #e1dddd, #dddddda7);
}
label[for="Qualification-File"]:active{
    box-shadow: inset 1px 1px 3px #ccc;
}
label[for="Resume-Info"]:active {
    box-shadow: inset 1px 1px 3px #ccc;
}

@media only screen and (max-width: 768px){
    .Tutor-SignUp-Button-Div button{
    height: 3rem;
    width: 10rem;
    }
}