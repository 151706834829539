.container{
    width: 100%;
    border: 1px solid yellow;
}
.first {
    width: 100%;
  padding: 2.8rem 3rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  background-color: var(--back-color);
}
.text {
  width: 100%;
  display: block;
  margin: auto 0;
  padding-top: 8rem;
}
.headText {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  font-size: var(--step-1);
  font-family: 'Red Hat Display', sans-serif;
  text-align: center;
  padding-bottom: 1rem;
  padding-right: 0;
  margin-top: -9rem;
}
.landText {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  width: 90%;
  font-size: var(--step-01);
  text-align: center;
}
.cta {
  width: 15rem;
  height: 4rem;
  border-radius: 11rem;
  margin:1rem;
}
.con{
    margin: 2.5rem 0;
}
.landImage {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  margin-top: 7rem; 
}
@media (max-width: 755px){
    .first{
        min-width: 100vw;
        padding: 0;
       justify-content: center;
       align-items: center;
    }
    .text{
        width: 100%;
        padding-left:2px ;
    }
    .headText{
        margin-top: 5rem;
        width: 100%;
        text-align: center;
        padding-left: 2rem;
    }
    .first{
        flex-direction: column;
        padding:0 1rem;
    }
    .cta{
        margin: 1rem ;
    }
    .landText{
        width: 100%;
    }
    .con{
        display: flex;
        justify-content: space-around;
    }
}
@media (max-width: 426px) {
    .first{
        padding-top:-2rem ;
        position: static;
        display: flex;
        flex-direction: column-reverse;
    }
    .text{
        padding-top:1rem ;
    }
    .headText{
        animation: slideInUp 1s forwards;
        margin: 0 auto;
        padding: 0 0.5rem;
        width: 100%;
    }
    .landImage{
        height: 300px;
        width: 300px;
        margin-top: 2rem;
    }
    
}
.thrivefuse{
    background-color: var(--back-color);
    margin:1rem 0;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    padding: 2.8rem 5.2rem;
}
.thrivefuseMessage{
    font-size: var(--step-4);
    line-height: 120%;
}
.aboutMess{
    font-size: var(--step-3);
    color: var(--main-color);
    font-weight: 300;
    text-shadow:  0 4px 8px rgba(0, 0, 0, 0.7);
    margin: 0;
}
.textAbou{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}
.enthusiastImage{
    height: 480px;
    width: 500px;
    border-radius: 20px;
    border: 3px solid var(--main-color);
    margin-top: 7rem;
}
@media only screen and (max-width: 768px){
    .thrivefuse{
        flex-wrap: wrap;
        margin: 0;
        width: 100vw;
        padding:0 1.5rem;
    }
    .enthusiastImage{
        width: 300px;
        height: 280px;
    }
    .SystemBox{
        width: 95%;
    }
}
@media (max-width: 426px) {
    .all{
        display: flex;
        flex-direction : column-reverse;
    }
    .thrivefuse{
        flex-wrap: wrap;
    }
    

}

.courses{
    background-color: var(--back-color);
    margin:1rem 0;
    padding: 2.8rem 5.2rem;
}
.courseExplore{
    text-align:left;
    color: var(--main-color);
    font-size: var(--step-1);
    display: block;
    line-height: 1.5;
    margin-left: -2rem;
    margin-bottom: 1rem;

}
.systemContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));;
    grid-gap: 3rem;
    place-items: center;
}
.SystemBox{
    width: 100%;
    display: block;
    margin: 0 auto;
    /* width: 95%; */
    gap: 2rem;
}
.systemImageBox{
    text-align: center;
    display: flex;
    justify-content: center;
}
.systemImage{
    width: 320px;
    height:180px ;
    margin: 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-self:center ;
}
.systemTitle{
    font-size: var(--step-1);
    margin: 0.5rem 1rem;
    color: var(--main-color);
    text-shadow:  0 4px 8px rgba(0, 0, 0, 0.7);
    font-weight: 500;
    text-align: center;
}
.systemText{
    font-size: 15px;
    margin: 1rem;
    display: flex;
    word-wrap: wrap;
    text-align: center;
}
.dataContainer{
    margin-top: 3rem;
}

@media (max-width: 426px) {
    .Slide{
        margin: 10px;
        width: 100%;
        border: 1px solid red;
    }
    .SystemBox{
        margin: 0;
        gap: 0rem;
        border: 1px solid green;
    }
    span{
        font-weight: 500;
    }
}



@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideInDown {
    from {
      transform: translateY(10vh);
      opacity: 0.5;
    }
    to {
      transform: translateY(100%);
      opacity: 1;
    }
  }